import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { Col, Container, Row, Alert, Label, Modal, CardBody } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import logodark from "../../assets/images/logo-dark.png";

import CarouselPage from "./CarouselPage";
import TermOfUse from "./TermOfUse";
import Policy from "./Policy";
// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";
import { connect } from "react-redux";
import {
  getIsUser,
} from "../../helpers/kyc_helper";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termModal: false,
      checkedPolicy: false,
      policyModal: false,
      email: '',
      password: '',
      passwordConfirmation: '',
      step: 1,
    };
  }
  changeCheck(e) {
    this.setState({
      checkedPolicy: e.target.checked
    })
  }

  togglemodal() {
    this.setState(prevState => ({
      termModal: !prevState.termModal,
    }))
  }

  togglePolicyModal() {

  }

  isUser(value) {
    getIsUser({
      email: value.email.toLowerCase()
    }).then(res => {
      this.setState(prevState => ({
        email: value.email.toLowerCase()
      }))
      this.setState(prevState => ({
        step: res.data.result ? 2 : 3
      }))
      
    })
  }

  componentDidMount() {
    this.props.apiError("");
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>Login</title>
          </MetaTags>
          <Container fluid className="p-0 max-none">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="38"
                            className="auth-logo-dark"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Welcome On Board</h5>
                          <p className="text-muted">
                            Exchange with a Certified Merchant now. Login to verify and get payment options.
                          </p>
                        </div>
                        <div className="mt-4">
                          {this.state.step == 1 ? (
                              <Formik
                                 enableReinitialize={true}
                                 initialValues={{
                                   email: (this.state && this.state.email) || "",
                                 }}
                                 validationSchema={Yup.object().shape({
                                   email: Yup.string().email("Invalid email address").required(
                                     "Please Enter Your Email"
                                   )
                                 })}
                                 onSubmit={value => {
                                   this.isUser(value)
                                 }}
                               >
                                 {({ errors, status, touched }) => (
                                   <Form className="form-horizontal">
                                     {this.props.error && this.props.error ? (
                                       <Alert color="danger">
                                         {this.props.error}
                                       </Alert>
                                     ) : null}
     
                                     <div className="mb-3">
                                       <Label for="email" className="form-label">
                                         Email
                                       </Label>
                                       <Field
                                         name="email"
                                         placeholder="Enter email"
                                         type="email"
                                         className={
                                           "form-control" +
                                           (errors.email && touched.email
                                             ? " is-invalid"
                                             : "")
                                         }
                                       />
                                       <ErrorMessage
                                         name="email"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </div>
     
     
                                     <div className="mt-3 d-grid">
                                       <button
                                         className="btn btn-primary btn-block"
                                         type="submit"
                                       >
                                         {" "}
                                         Next{" "}
                                       </button>
                                     </div>
     
                                   </Form>
                                 )}
                               </Formik>
                          ): this.state.step == 2 ? (
                            <Formik
                            enableReinitialize={true}
                            initialValues={{
                              password:
                                (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string().required ( "No password provided." )
                              . min (6 , "Password is too short - should be 6 chars minimum." )
                              . matches ( /(?=.*[0-9])/ , "Password must contain a number." )
                            })}
                            onSubmit={values => {
                              this.props.loginUser({
                                ...values,
                                email: this.state.email,
                              }, this.props.history);
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                                  <div className="float-end">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"

                                    onChange={
                                      this.changeCheck.bind(this)
                                    }
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    I have read and agree to the BiTu’s  <Link to="#" onClick={()=>this.setState({termModal: true})} className="text-primary">
                                    Terms and Conditions
                                    </Link> and <Link to="#" onClick={()=>this.setState({policyModal: true})} className="text-primary">
                                      Privacy Policy
                                    </Link>
                                  </label>
                                </div>
                          
                                <div className="mt-3 d-grid">
                                  <button
                                    disabled={!this.state.checkedPolicy}
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    {" "}
                                    Login{" "}
                                  </button>
                                  <div style={{
                                    textAlign: "right"
                                  }}>
                                    <Link to="#" onClick={()=>this.setState({step: 1})} className="text-primary">
                                      &lt; Re enter email address
                                    </Link>
                                   
                                  </div>
                                </div>

                              </Form>
                            )}
                          </Formik>
                          ) : (
                            <Formik
                            enableReinitialize={true}
                            initialValues={{
                              password:
                                (this.state && this.state.password) || "",
                              confirmPassword:
                                (this.state && this.state.confirmPassword) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string().required ( "No password provided." )
                              . min (6 , "Password is too short - should be 6 chars minimum." )
                              . matches ( /(?=.*[0-9])/ , "Password must contain a number." ),
                              passwordConfirmation: Yup.string()
                              .oneOf([Yup.ref('password'), null], 'Passwords must match')
                            })}
                            onSubmit={values => {
                              console.log('0000')
                              this.props.loginUser({
                                ...values,
                                email: this.state.email,
                                isRegister: true
                              }, this.props.history);
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                             
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                          
                                  <Label for="passwordConfirmation" className="form-label">
                                    Password Confirmation
                                  </Label>
                                  <Field
                                    name="passwordConfirmation"
                                    placeholder="Enter Again"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.passwordConfirmation && touched.passwordConfirmation
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="passwordConfirmation"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"

                                    onChange={
                                      this.changeCheck.bind(this)
                                    }
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    I have read and agree to the BiTu’s  <Link to="#" onClick={()=>this.setState({termModal: true})} className="text-primary">
                                    Terms and Conditions
                                    </Link> and <Link to="#" onClick={()=>this.setState({policyModal: true})} className="text-primary">
                                      Privacy Policy
                                    </Link>
                                  </label>
                                </div>
                                <div className="mt-3 d-grid">
                                  <button
                                    disabled={!this.state.checkedPolicy}
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    {" "}
                                    Register{" "}
                                  </button>
                                  <div style={{
                                    textAlign: "right"
                                  }}>
                                    <Link to="#" onClick={()=>this.setState({step: 1})} className="text-primary">
                                      &lt; Re enter email address
                                    </Link>
                                   
                                  </div>
                                </div>

                              </Form>
                            )}
                          </Formik>
                          )}
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} BiTu. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          BiTu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <TermOfUse termModal={this.state.termModal} toggle={this.togglemodal.bind(this)} close={() => this.setState({termModal: false})}></TermOfUse>
          <Policy termModal={this.state.policyModal} toggle={this.togglePolicyModal.bind(this)} close={() => this.setState({policyModal: false})}></Policy>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

// export default Login;
export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Nav,
  Input,
  InputGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import PropTypes from "prop-types";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import {
  getUserList,
  getUserLogs,
  getTransactions,
  sendEmail,
  getZeList,
  genPayid,
  updateZe,
  downLoadReport,
  gen_payid_manual,
} from "../../helpers/kyc_helper";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { envelopeItemTypeToDataCategory } from "@sentry/utils";
import * as moment from "moment";
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isBind: false,
      modal: false,
      zeModal: false,
      userList: [],
      zeList: [],
      transList: [],
      userPageIndex: 1,
      selectedUserID: null,
      zePageIndex: 1,
      name: "",
      email: "",
      userCount: 0,
      zeCount: 0,
      userPageList: [],
      transPageIndex: 1,
      transCount: 0,
      logs: "",
      logList: [],
      transPageList: [],
      limit: 20,
      zeForm: {
        firstName: '',
        lastName: '',
        email: '',
      },
      customIconActiveTab: "1",
    };
    this.request = null;
    this.questions = [
      {
        q: "Purpose of buying Crypto?",
        value: "q1",
        a: [
          {
            value: "a1",
            text: "Trading Crypto on Binance/Huobi/Okex",
          },
          {
            value: "a2",
            text: "Buying NFT",
          },
          {
            value: "a3",
            text: "Investing forex trading(MT4/MT5)",
            refuse: true,
          },
          {
            value: "a4",
            text: "Withdraw to others’ wallet address",
            refuse: true,
          },
        ],
      },
      {
        q: "Does anyone ask you to buy Crypto from us or say that we are their partner?",
        value: "q2",
        a: [
          {
            value: "a1",
            text: "Yes",
            refuse: true,
          },
          {
            value: "a2",
            text: "No",
          },
        ],
      },
      {
        q: "Does anyone teach you to trade Crypto?",
        value: "q3",
        a: [
          {
            value: "a1",
            text: "Yes. Someone I met online.",
            refuse: true,
          },
          {
            value: "a2",
            text: "Yes. My family or friends whom I met physically.",
          },
          {
            value: "a3",
            text: "No.",
          },
        ],
      },
      {
        q: "What will you do if you suspicion yourself is scammed by someone and you lose the crypto which is purchased from BiTu?",
        value: "q4",
        a: [
          {
            value: "a1",
            text: "Dispute my bank transfer through my bank",
            refuse: true,
          },
          {
            value: "a2",
            text: "I acknowledge that the seller (BiTu) is not responsible for the management of the assets after the purchase.",
          },
        ],
      },
    ];
  }

  componentDidMount() {
    this.getUserList();
    this.getTrans();
  }
  sendEmail(item) {
    this.setState({ loading: true });
    item.loading = true;
    try {
      sendEmail({
        email: item.Email,
        id: item.ID,
      }).then(() => {
        item.loading = false;
        alert("The email has been sent successfully");
        this.setState({ loading: false });
      });
    } catch (e) {
      item.loading = false;
      this.setState({ loading: false });
      alert("Mail sending failed");
    }
  }
  downLoadReport(item) {
    try {
      downLoadReport(
        {
          email: item.Email,
          id: item.ID,
        },
        {
          responseType: "blob",
        }
      ).then(response => {
        // console.log(response)
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BITU_" + +new Date() + "_file.pdf");
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      alert("Download report failed");
    }
  }
  gen_payid_manual(item) {
    try {
      gen_payid_manual({
        email: item.Email,
        id: item.ID,
      }).then(response => {
        this.getUserList();
      });
    } catch (e) {
      alert("Failed");
    }
  }
  openModal(item) {
    this.setState({
      modal: true,
      logList: [],
    });
    getUserLogs({ id: item.ID }).then(res => {
      this.setState({
        logList: res.data.map(item => {
          return {
            ...item,
            CreatedAt: moment(item.CreatedAt).format("MM-DD-YYYY HH:mm"),
          };
        }),
      });
    });
  }
  openZeModal(item) {
    if (item && item.ID) {
      this.setState({
        selectedUserID: item.ID,
        isBind: true
      });
    }
    this.setState({
      zeModal: true,
      zePageIndex: 1,
      zeList: [],
    });
    this.getZeList();
  }
  getZeList(index) {
    getZeList({
      page: index ? index : this.state.zePageIndex,
      page_size: this.state.limit,
    }).then(res => {
      this.setState({
        zeCount: res.data.count,
        zeList: res.data.data,
      });
    });
  }
  getUserList(index) {
    getUserList({
      limit: this.state.limit,
      page: index ? index : this.state.userPageIndex,
      page_size: this.state.limit,
      name: this.state.name,
      email: this.state.email,
    }).then(res => {
      const list = res.data.data || [];
      const pages = [];
      const pageSize = Math.ceil((res.data.count || 0) / this.state.limit);

      for (let i = 0; i < pageSize; i++) {
        pages.push(i + 1);
      }
      this.setState({
        userCount: res.data.count,
        userPageList: pages,
      });
      list.forEach(item => {
        const json = JSON.parse(item.qa || "{}");
        const questions = [];
        this.questions.forEach(q => {
          q.a.forEach(a => {
            if (json[q.value] === a.value) {
              questions.push(a.text);
            }
          });
        });
        item.questions = questions;
      });
      this.setState({
        userList: list,
      });
    });
  }

  getTrans(index) {
    getTransactions({
      limit: this.state.limit,
      page: index ? index : this.state.transPageIndex,
    }).then(res => {
      const list = res.data.data || [];
      const pages = [];
      const pageSize = Math.ceil((res.data.count || 0) / this.state.limit);

      for (let i = 0; i < pageSize; i++) {
        pages.push(i + 1);
      }
      this.setState({
        transCount: res.data.count,
        transPageList: pages,
      });
      this.setState({
        transList: list,
      });
    });
  }

  approveNote() {}
  toggleIconCustom(tab) {
    if (tab === "1") {
      this.getUserList();
    } else {
      this.getTrans();
    }
    this.setState({
      customIconActiveTab: tab,
    });
  }

  componentWillUnmount() {}

  goUserPage(index) {
    this.setState(
      {
        userPageIndex: index,
      },
      () => {
        this.getUserList();
      }
    );
  }
  changeFilter(event) {
    this.setState(
      {
        name: event.target.value,
      },
      () => {
        this.getUserList(1);
      }
    );
  }
  changeEmail(event) {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        this.getUserList(1);
      }
    );
  }

  goTransPage(index) {
    this.setState({
      transPageIndex: index,
    });
    this.getTrans(index);
  }
  goZePage(index) {
    this.setState({
      zePageIndex: index,
    });
    this.getZeList(index);
  }
  togglemodal() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleZeModal() {
    this.setState(prevState => ({
      zeModal: !prevState.zeModal,
    }));
  }
  genPayid() {
    genPayid({
      first_name: this.state.zeForm.firstName,
      last_name: this.state.zeForm.lastName,
      email: this.state.zeForm.email,
    }).then(() => {
      this.getZeList()
    })
  }
  changeZeForm(type, event) {
    console.log(type, event.target.value)
    this.setState(
      {
        zeForm: {
          ...this.state.zeForm,
          [type]: event.target.value,
        },
      }, () => {
        console.log(this.state.zeForm)
      })
      
  }
  bindUser(item) {
    updateZe({
      zepto_id : item.ID,
      user_id: this.state.selectedUserID
    }).then(() => {
      this.setState({
        zeModal: false
      })
      this.getUserList();
    })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-2">
              <Col md={12}>
                <Nav className="icon-tab nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customIconActiveTab === "1",
                      })}
                      onClick={() => {
                        this.toggleIconCustom("1");
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="fas fa-user"></i> User
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="fas fa-user"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customIconActiveTab === "2",
                      })}
                      onClick={() => {
                        this.toggleIconCustom("2");
                      }}
                    >
                      <span className="d-none d-sm-block">
                        <i className="fas fa-clipboard-list"></i> Order
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="fas fa-clipboard-list"></i>
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={this.state.customIconActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>
                            <div>
                              <InputGroup
                                className="mb-2 mr-2"
                                style={{
                                  float: "right",
                                  width: 200,
                                  lineHeight: "36px",
                                }}
                              >
                                <span className="input-group-text">User</span>
                                <Input
                                  onInput={event => this.changeFilter(event)}
                                />
                              </InputGroup>
                              <InputGroup
                                className="mb-2 mr-2"
                                style={{
                                  float: "right",
                                  width: 250,
                                  marginRight: 20,
                                  lineHeight: "36px",
                                }}
                              >
                                <span className="input-group-text">Email</span>
                                <Input
                                  onInput={event => this.changeEmail(event)}
                                />
                              </InputGroup>
                            </div>
                            <div>
                              <button
                                className="btn btn-light mb-2"
                                type="button"
                                style={{
                                  float: "right",
                                  marginRight: 20,
                                  width: 100,
                                }}
                                onClick={this.openZeModal.bind(this)}
                              >
                                Zepto 列表
                              </button>
                            </div>
                            <Modal
                              isOpen={this.state.zeModal}
                              role="dialog"
                              size="xl"
                              autoFocus={true}
                              centered
                              id="verificationModal"
                              toggle={this.toggleZeModal.bind(this)}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Zepto
                                </h5>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.setState({ zeModal: false })
                                  }
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <InputGroup
                                    className="mb-2 mr-2"
                                    style={{
                                      width: 250,
                                      float: "left",
                                      marginRight: "20",
                                      lineHeight: "36px",
                                    }}
                                  >
                                    <span className="input-group-text">
                                      First Name
                                    </span>
                                    <Input
                                      onInput={event =>
                                        this.changeZeForm('firstName', event)
                                      }
                                    />
                                  </InputGroup>
                                  <InputGroup
                                    className="mb-2 ml-2"
                                    style={{
                                      width: 250,
                                      marginLeft: 10,
                                      float: "left",
                                      lineHeight: "36px",
                                    }}
                                  >
                                    <span className="input-group-text">
                                      Last Name
                                    </span>
                                    <Input
                                      onInput={event => this.changeZeForm('lastName', event)}
                                    />
                                  </InputGroup>
                                  <InputGroup
                                    className="mb-2 mr-2"
                                    style={{
                                      width: 250,
                                      marginLeft: 10,
                                      float: "left",
                                      lineHeight: "36px",
                                    }}
                                  >
                                    <span className="input-group-text">
                                      Email
                                    </span>
                                    <Input
                                      onInput={event => this.changeZeForm('email', event)}
                                    />
                                  </InputGroup>
                                  <button
                                    className="btn btn-light mb-2"
                                    type="button"
                                    style={{
                                      marginLeft: 10,
                                      width: 100,
                                    }}
                                    disabled = {this.state.zeForm.firstName === '' || this.state.zeForm.lastName === '' || this.state.zeForm.email === ''}
                                    onClick={this.genPayid.bind(this)}
                                  >
                                    生成
                                  </button>
                                </div>
                                <div>
                                  <Table className="table mb-0">
                                    <thead>
                                      <tr
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <th>#</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Account Number</th>
                                        <th>Bank Account Id</th>
                                        <th>Pay Id</th>
                                        <th class="txx"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.zeList.map(
                                        (item, index) => (
                                          <tr
                                            key={index}
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <th scope="row">{item.ID}</th>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.account_number}</td>
                                            <td>{item.bank_account_id}</td>
                                            <td>{item.pay_id}</td>
                                            {
                                              this.state.isBind ? (   <td class="txx">
                                              <button
                                                className="btn btn-light mb-2"
                                                style={{ marginRight: 10 }}
                                                type="button"
                                                onClick={this.bindUser.bind(
                                                  this,
                                                  item
                                                )}
                                              >
                                                绑定
                                              </button>
                                            </td>) : ''
                                            }
                                         
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                  <div className="mt-2 float-end">
                                    <Pagination
                                      onChange={pageNum =>
                                        this.goZePage(pageNum)
                                      }
                                      total={this.state.zeCount}
                                      showQuickJumper
                                      pageSize={this.state.limit}
                                      current={this.state.zePageIndex}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            <CardTitle className="h4">用户列表</CardTitle>
                            <p className="card-title-desc">
                              用户列表，展示最近注册的用户
                            </p>
                            <div>
                              <div className="mt-2">
                                {this.state.userCount}
                                <Pagination
                                  onChange={pageNum => this.goUserPage(pageNum)}
                                  total={this.state.userCount}
                                  showQuickJumper
                                  pageSize={this.state.limit}
                                  current={this.state.userPageIndex}
                                />
                              </div>
                            </div>
                            <Modal
                              isOpen={this.state.modal}
                              role="dialog"
                              size="md"
                              autoFocus={true}
                              centered
                              id="verificationModal"
                              toggle={this.togglemodal.bind(this)}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Logs
                                </h5>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.setState({ modal: false })
                                  }
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {this.state.logList.map((item, index) => (
                                  <div
                                    style={{
                                      marginBottom: 12,
                                      maxHeight: 300,
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div>
                                      {item.CreatedAt} <b>{item.Location}</b>[
                                      {item.Ip}]
                                    </div>
                                    <div>
                                      Action: <i>{item.URI}</i>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Modal>
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead>
                                  <tr
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <th>#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Account Number</th>
                                    <th>Bank Account Id</th>
                                    <th>Pay Id</th>
                                    <th>Q&A?</th>
                                    <th>KYC?</th>
                                    <th>Register IP?</th>
                                    <th>Answers</th>
                                    <th class="txx"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.userList.map((item, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <th scope="row">{item.ID}</th>
                                      <td>{item.FirstName}</td>
                                      <td>{item.LastName}</td>
                                      <td>{item.Email}</td>
                                      <td>{item.account_number}</td>
                                      <td>{item.bank_account_id}</td>
                                      <td>{item.pay_id}</td>
                                      <td>
                                        {item.is_qa === 1
                                          ? "通过"
                                          : item.is_qa === 0
                                          ? "未通过"
                                          : ""}
                                      </td>
                                      <td>
                                        {item.kyc_status === "GREEN"
                                          ? "通过"
                                          : item.kyc_status === "RED"
                                          ? "未通过"
                                          : ""}
                                      </td>
                                      <td>{item.register_ip}</td>
                                      <td>
                                        {item.questions.map((q, index) => (
                                          <div>
                                            {index + 1}. {q}
                                            <br />
                                          </div>
                                        ))}
                                      </td>
                                      <td class="txx">
                                        <button
                                          className="btn btn-light mb-2"
                                          style={{ marginRight: 10 }}
                                          type="button"
                                          onClick={this.sendEmail.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          {item.loading ? (
                                            <i className="bx bx-hourglass bx-spin font-size-16"></i>
                                          ) : (
                                            <i className="mdi mdi-email-send-outline font-size-16"></i>
                                          )}
                                        </button>
                                        <button
                                          className="btn btn-light mb-2"
                                          style={{ marginRight: 10 }}
                                          type="button"
                                          onClick={this.downLoadReport.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          <i className="mdi mdi-download font-size-16"></i>
                                        </button>
                                        {!item.pay_id ? (
                                          <button
                                            className="btn btn-light mb-2"
                                            style={{
                                              marginRight: 10,
                                              color: "red",
                                            }}
                                            type="button"
                                            onClick={this.openZeModal.bind(
                                              this,
                                              item
                                            )}
                                          >
                                            <i className="mdi mdi-download font-size-16"></i>
                                          </button>
                                        ) : null}

                                        <button
                                          className="btn btn-light mb-2"
                                          type="button"
                                          onClick={this.openModal.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          <i className="mdi mdi-account font-size-16"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div className="mt-2 float-end">
                                <Pagination
                                  onChange={pageNum => this.goUserPage(pageNum)}
                                  total={this.state.userCount}
                                  showQuickJumper
                                  pageSize={this.state.limit}
                                  current={this.state.userPageIndex}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">订单</CardTitle>
                            <p className="card-title-desc">订单列表</p>

                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>User Email</th>
                                    <th>Currency</th>
                                    <th>Pay method</th>
                                    <th>Amount ($)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.transList.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.ID}</td>
                                      <td>{item.UserEmail}</td>
                                      <td>{item.Currency}</td>
                                      <td>{item.PayMethod}</td>
                                      <td>{item.Amount}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div className="mt-2 float-end">
                                <Pagination
                                  onChange={pageNum =>
                                    this.goTransPage(pageNum)
                                  }
                                  total={this.state.transCount}
                                  showQuickJumper
                                  pageSize={this.state.limit}
                                  current={this.state.transPageIndex}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  history: PropTypes.object,
};

export default Users;

// Login Method
import { post, get } from "./api_helper";
import * as url from "./url_helper";

export const getKYCToken = data => post(url.GET_KYC_TOKEN, data)
export const saveQA = data => post(url.POST_QUESTION, data)
export const saveTransaction = data => post(url.SAVE_TRANSACTION, data)
export const getUserInfo = data => get(url.GET_USER_INFO, data)
export const getIsUser = data => post(url.HAS_USER, data)
export const logOut = data => get(url.LOGOUT, data)
export const getUserList = data => post(url.USER_LIST, data)
export const getUserLogs = data => post(url.USER_LOGS, data)
export const getTransactions = data => get(url.TRANSACTIONS, {
  params: data
})
export const initPoli = data => post(url.INIT_POLI, data)
export const getZeList = data => post(url.ZE_LIST, data)
export const genPayid = data => post(url.GEN_PAYID, data)
export const updateZe = data => post(url.UPDATE_ZE, data)
export const getPoli = data => post(url.GET_POLI, data)
export const sendEmail = data => post(url.SEND_EMAIL, data)
export const downLoadReport = (data, option) => post(url.DOWNLOAD_REPORT, data, option)
export const gen_payid_manual = (data, option) => post(url.gen_payid_manual, data)

import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Modal,
  Collapse,
  InputGroup,
  NavItem,
  NavLink,
} from "reactstrap";

import { Formik } from "formik";
import PropTypes from "prop-types";
import proImg from "../../assets/images/crypto/features-img/img-2.png";
import c1 from "../../assets/images/crypto/c1.png";
import c2 from "../../assets/images/crypto/c2.png";
import c3 from "../../assets/images/crypto/c3.png";
import c4 from "../../assets/images/crypto/poli.png";
import c5 from "../../assets/australia.png";
import c6 from "../../assets/new-zealand.png";
import {
  saveTransaction,
  getUserInfo,
  initPoli,
} from "../../helpers/kyc_helper";
import TermOfUse from "../Authentication/TermOfUse";
import Policy from "../Authentication/Policy";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

class Crypto extends Component {
  constructor(props) {
    super(props);
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser) || { Email: "" };
    this.state = {
      currency: "USDT",
      pay_currency: "AUD",
      amount: 0,
      pay_method: "payid",
      loading: false,
      modal: false,
      faq1: false,
      faq2: false,
      faq3: false,
      termModal: false,
      checkedPolicy: false,
      policyModal: false,
      user,
    };
    this.request = null;
  }

  componentDidMount() {}

  submit() {
    this.setState({
      modal: true,
    });
    getUserInfo().then(res => {
      const data = res.data;
      const _user = {
        ...data,
        uid: data.ID,
        username: data.FirstName,
        role: "admin",
        email: data.email,
      };
      localStorage.setItem("authUser", JSON.stringify(_user));
    });
  }

  approveNote() {
    this.setState({
      loading: true,
    });
    // console.log(this.state.pay_method);
    if (this.state.pay_method === "poli") {
      initPoli({
        amount: this.state.amount,
        buy_currency_code: this.state.currency,
        pay_currency_code: this.state.pay_currency,
        is_agreement: true,
      }).then(res => {
        this.setState({
          loading: false,
        });
        this.setState({
          modal: false,
        });
        window.location.href = res.data.navigate_url;
        // var iLeft = (window.screen.width - 460) / 2;
        // window.open(
        //   res.data.navigate_url,
        //   "newwindow",
        //   "height=800,width=450,left=" +
        //     iLeft +
        //     ",toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no"
        // );
      });
    } else {
      try {
        this.request = setInterval(() => {
          getUserInfo().then(
            res => {
              if (res && res.data && res.data.pay_id_state === "active") {
                clearInterval(this.request);
                this.setState({
                  loading: false,
                });
                saveTransaction({
                  currency: this.state.currency,
                  amount: Number(this.state.amount),
                  pay_method: this.state.pay_method,
                  is_agreement: true,
                }).then(
                  () => {
                    localStorage.setItem("method", this.state.pay_method);
                    localStorage.setItem("amount", this.state.amount);
                    localStorage.setItem("currency", this.state.currency);
                    this.props.history.push("/account");
                  },
                  () => {
                    if (this.request) {
                      clearInterval(this.request);
                    }
                    this.setState({
                      loading: false,
                    });
                    this.setState({
                      modal: false,
                    });

                    toastr.error(
                      "Network exception, please refresh the page",
                      ""
                    );
                  }
                );
              }
            },
            () => {
              if (this.request) {
                clearInterval(this.request);
              }
              this.setState({
                loading: false,
              });
              this.setState({
                modal: false,
              });

              toastr.error("Network exceptions, please refresh the page", "");
            }
          );
        }, 5000);
      } catch (e) {
        if (this.request) {
          clearInterval(this.request);
        }
        this.setState({
          loading: false,
        });
        this.setState({
          modal: false,
        });

        toastr.error("Network exception, please refresh the page.", "");
      }
    }
  }

  componentWillUnmount() {
    if (this.request) {
      clearInterval(this.request);
    }
  }
  changeCurrency(e) {
    this.setState({
      currency: e.target.value,
    });
  }
  changePayCurrency(e) {
    this.setState({
      pay_currency: e.target.value,
    });
  }
  changeMethod(e) {
    this.setState({
      pay_method: e.target.value,
    });
  }
  changeAmount(e) {
    this.setState({
      amount: e.target.value,
    });
  }

  togglemodal() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleTermModal() {
    this.setState(prevState => ({
      termModal: !prevState.termModal,
    }));
  }

  togglePolicyModal() {
    this.setState(prevState => ({
      policyModal: !prevState.policyModal,
    }));
  }

  toggleFaq(index) {
    this.setState({
      faq1: index === 1 ? !this.state.faq1 : false,
      faq2: index === 2 ? !this.state.faq2 : false,
      faq3: index === 3 ? !this.state.faq3 : false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Get transfer detail</title>
          </MetaTags>
          <Container fluid>
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem>
                    <NavLink>
                      <span className="number">1.</span>
                      Questionnaire
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <span className="number">2.</span>
                      ID Verification
                    </NavLink>
                  </NavItem>
                  <NavItem className="current">
                    <NavLink className="active">
                      <span className="number">3.</span>
                      Get PayID/Bank Account detail
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </div>
            <Row className="justify-content-center mt-lg-5 clearfix">
              <Col xxl="6" xl="8" md="8" className="mt-2">
                <Card>
                  <CardBody>
                    <div className="crypto-buy-sell-nav">
                      <Row className="justify-content-center">
                        <Col lg="12">
                          <Formik enableReinitialize={true}>
                            <Form>
                              <div className="mb-2">
                                <Label>Crypto :</Label>

                                <Row>
                                  <Col xl="4" sm="4">
                                    <div className="mb-3">
                                      <Label className="card-radio-label mb-2">
                                        <Input
                                          type="radio"
                                          name="currency"
                                          value="USDT"
                                          id="buycurrencyoption3"
                                          className="card-radio-input"
                                          defaultChecked
                                          onChange={this.changeCurrency.bind(
                                            this
                                          )}
                                        />
                                        {/* TODO: icon 需要改一下*/}
                                        <div
                                          className="card-radio"
                                          style={{ height: 72 }}
                                        >
                                          <div>
                                            <img src={c2} alt="" height={20} />{" "}
                                            <span
                                              style={{
                                                lineHeight: "36px",
                                                marginLeft: 10,
                                              }}
                                            >
                                              USDT
                                            </span>
                                          </div>
                                        </div>
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col xl="4" sm="4">
                                    <div className="mb-3">
                                      <Label className="card-radio-label mb-2">
                                        <Input
                                          type="radio"
                                          name="currency"
                                          id="buycurrencyoption1"
                                          value="Bitcoin"
                                          className="card-radio-input"
                                          onChange={this.changeCurrency.bind(
                                            this
                                          )}
                                          readOnly
                                        />

                                        <div className="card-radio">
                                          <div>
                                            <i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2" />{" "}
                                            <span>Bitcoin</span>
                                          </div>
                                        </div>
                                      </Label>
                                    </div>
                                  </Col>

                                  <Col xl="4" sm="4">
                                    <div className="mb-3">
                                      <Label className="card-radio-label mb-2">
                                        <Input
                                          type="radio"
                                          name="currency"
                                          value="Ethereum"
                                          id="buycurrencyoption2"
                                          className="card-radio-input"
                                          onChange={this.changeCurrency.bind(
                                            this
                                          )}
                                        />

                                        <div className="card-radio">
                                          <div>
                                            <i className="mdi mdi-ethereum font-size-24 text-primary align-middle me-2" />{" "}
                                            <span>Ethereum</span>
                                          </div>
                                        </div>
                                      </Label>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mb-2">
                                <Label>Currency :</Label>

                                <Row>
                                  <Col xl="4" sm="4">
                                    <Label className="card-radio-label mb-3">
                                      <Input
                                        type="radio"
                                        name="pay_currency"
                                        id="pay-methodoption3"
                                        className="card-radio-input"
                                        value="AUD"
                                        defaultChecked
                                        readOnly
                                        onChange={this.changePayCurrency.bind(
                                          this
                                        )}
                                      />

                                      <div className="card-radio">
                                        <img src={c5} alt="" height={23} />{" "}
                                        <span
                                          style={{
                                            lineHeight: "36px",
                                            marginLeft: 10,
                                          }}
                                        >
                                          AUD
                                        </span>
                                      </div>
                                    </Label>
                                  </Col>

                                    {/*<Col xl="4" sm="4">*/}
                                    {/*  <Label className="card-radio-label mb-3">*/}
                                    {/*    <Input*/}
                                    {/*      type="radio"*/}
                                    {/*      name="pay_currency"*/}
                                    {/*      id="pay-methodoption1"*/}
                                    {/*      className="card-radio-input"*/}
                                    {/*      value="NZD"*/}
                                    {/*      onChange={this.changePayCurrency.bind(*/}
                                    {/*        this*/}
                                    {/*      )}*/}
                                    {/*    />*/}

                                    {/*    <div className="card-radio">*/}
                                    {/*      <img src={c6} alt="" height={23} />{" "}*/}
                                    {/*      <span*/}
                                    {/*        style={{*/}
                                    {/*          lineHeight: "36px",*/}
                                    {/*          marginLeft: 10,*/}
                                    {/*        }}*/}
                                    {/*      >*/}
                                    {/*        NZD*/}
                                    {/*      </span>*/}
                                    {/*    </div>*/}
                                    {/*  </Label>*/}
                                    {/*</Col>*/}

                                </Row>
                              </div>
                              <div className="mb-2">
                                <Label>Payment method :</Label>

                                <Row>
                                  {this.state.pay_currency != "NZD" ? (
                                    <Row style={{ width: "100%" }}>
                                      <Col xl="4" sm="4">
                                        <Label className="card-radio-label mb-3">
                                          <Input
                                            type="radio"
                                            name="pay_method"
                                            id="pay-methodoption3"
                                            className="card-radio-input"
                                            value="payid"
                                            defaultChecked
                                            readOnly
                                            onChange={this.changeMethod.bind(
                                              this
                                            )}
                                          />

                                          <div className="card-radio">
                                            <img src={c1} alt="" width={60} />{" "}
                                            <span
                                              style={{
                                                lineHeight: "36px",
                                                marginLeft: 10,
                                              }}
                                            >
                                              PayID/Osko
                                            </span>
                                          </div>
                                        </Label>
                                      </Col>
                                      <Col xl="4" sm="4">
                                        <Label className="card-radio-label mb-3">
                                          <Input
                                            type="radio"
                                            name="pay_method"
                                            id="pay-methodoption1"
                                            className="card-radio-input"
                                            value="card"
                                            onChange={this.changeMethod.bind(
                                              this
                                            )}
                                          />

                                          <div className="card-radio">
                                            <img src={c3} alt="" width={60} />{" "}
                                            <span
                                              style={{
                                                lineHeight: "36px",
                                                marginLeft: 10,
                                              }}
                                            >
                                              Direct Credit
                                            </span>
                                          </div>
                                        </Label>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Col xl="6" sm="6">
                                      <Label className="card-radio-label mb-3">
                                        <Input
                                          type="radio"
                                          name="pay_method"
                                          id="pay-methodoption1"
                                          className="card-radio-input"
                                          value="poli"
                                          onChange={this.changeMethod.bind(
                                            this
                                          )}
                                        />

                                        <div className="card-radio">
                                          <img src={c4} alt="" width={60} />{" "}
                                          <span
                                            style={{
                                              lineHeight: "36px",
                                              marginLeft: 10,
                                            }}
                                          >
                                            POLi Internet banking
                                          </span>
                                        </div>
                                      </Label>
                                    </Col>
                                  )}
                                </Row>
                              </div>

                              <div className="mb-3">
                                <Label>Amount :</Label>

                                <Row>
                                  <Col sm="12">
                                    <InputGroup className="mb-2">
                                      <Input
                                        type="number"
                                        onChange={this.changeAmount.bind(this)}
                                        className="form-control font-size-16"
                                      />
                                      <span className="input-group-text">
                                        {this.state.pay_currency}
                                      </span>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </div>

                              <div className="text-center mt-4">
                                <Button
                                  disabled={
                                    !this.state.amount && this.state.amount <= 0
                                  }
                                  type="button"
                                  color="success"
                                  onClick={this.submit.bind(this)}
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          </Formik>
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-5 mb-2">
                        <Col sm="6" xs="8">
                          <div>
                            <img src={proImg} alt="" className="img-fluid" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Modal
                      isOpen={this.state.modal}
                      role="dialog"
                      size="md"
                      autoFocus={true}
                      centered
                      id="verificationModal"
                      toggle={this.togglemodal.bind(this)}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Important Notes
                        </h5>
                        <button
                          type="button"
                          onClick={() => this.setState({ modal: false })}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="pl-2">
                          <i className="mdi mdi-card-account-details text-pri"></i>
                          <p>
                            In line with AML/CTF laws, transfers are only
                            accepted from the bank account that is in the same
                            name as the account you have verified on Binance
                            Australia, Huobi Global and OKex.
                          </p>
                        </div>
                        <div className="pl-2">
                          <i className="bx bx-bitcoin text-pri"></i>
                          <p>
                            To cover processing costs, a AU$3 processing fee
                            will be charged to refund deposits received from
                            third-party bank accounts. Deposits will only be
                            refunded if the deposit is greater than AU$10.
                          </p>
                        </div>
                        <div className="pl-2">
                          <i className="bx bxs-bank text-pri"></i>
                          <p>
                            This service is supported by BiTu, in accordance to
                            BiTu’s{" "}
                            <Link
                              to="#"
                              onClick={() => this.setState({ termModal: true })}
                              className="text-primary"
                            >
                              Terms and Conditions
                            </Link>{" "}
                            and{" "}
                            <Link
                              to="#"
                              onClick={() =>
                                this.setState({ policyModal: true })
                              }
                              className="text-primary"
                            >
                              Privacy Policy
                            </Link>
                            .
                          </p>
                        </div>
                        <div className="pl-2">
                          <i className="mdi mdi-clock text-pri"></i>
                          <p>
                            Failed deposits will be returned to the source
                            account within 2 business days.
                          </p>
                        </div>
                        <div className="pl-2">
                          <i className="mdi mdi-hours-24 text-pri"></i>
                          <p>
                            Your first transfer may take 24 hours to clear
                            subject to your bank&apos;s policy. Subsequent
                            transfers are instant.
                          </p>
                        </div>
                        <div className="pl-2">
                          <i className="mdi mdi-monitor-eye text-pri"></i>
                          <p>
                            The seller (BiTu) is not responsible for the
                            management of the assets after the purchase order
                            completed.
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.approveNote();
                          }}
                        >
                          {this.state.loading ? (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          ) : (
                            ""
                          )}
                          I agree
                        </button>
                      </div>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
              <Col xxl="3" xl="4" md="4" className="mb-5">
                <div
                  className="accordion accordion-flush mt-2"
                  style={{ marginBottom: 20 }}
                  id="accordionFlushExample"
                >
                  <h3
                    style={{
                      paddingLeft: "1em",
                    }}
                  >
                    FAQ
                  </h3>
                  <div className="">
                    <h2 className="accordion-header" id="headingFlushOne">
                      <button
                        className="accordion-button fw-medium collapsed"
                        onClick={this.toggleFaq.bind(this, 1)}
                        type="button"
                        style={{ cursor: "pointer" }}
                      >
                        What bank accounts can I use to make payments?
                      </button>
                    </h2>

                    <Collapse
                      isOpen={this.state.faq1}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="text-muted">
                          Payments from your personal bank account are only
                          accepted. In line with AML/CTF laws, deposits are
                          accepted from the bank account that you have verified
                          on Binance Australia, Huobi Global or OKex. Payments
                          from third party bank account will be returned to the
                          source bank account withing 2 business days.
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="">
                    <h2 className="accordion-header" id="headingFlushTwo">
                      <button
                        className="accordion-button fw-medium collapsed"
                        type="button"
                        onClick={this.toggleFaq.bind(this, 2)}
                        style={{ cursor: "pointer" }}
                      >
                        How long does the payment take?
                      </button>
                    </h2>

                    <Collapse
                      isOpen={this.state.faq2}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="text-muted">
                          Your first PayID/Direct Credit payment may take 24
                          hours to process subjected to your bank’s policy.
                          Subsequent transfers are instant. BiTu will release
                          the crypto to your account on Binance Australia, Huobi
                          Global or OKex once the payment clear.
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushThree">
                      <button
                        className="accordion-button fw-medium collapsed"
                        type="button"
                        onClick={this.toggleFaq.bind(this, 3)}
                        style={{ cursor: "pointer" }}
                      >
                        What if my payment do not arrive in time?
                      </button>
                    </h2>
                    <Collapse
                      isOpen={this.state.faq3}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="text-muted">
                          Paying more than your bank daily limit may cause
                          delays to your payment. Failed payment will be
                          returned to the source bank account withing 2 business
                          days.
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <TermOfUse
            termModal={this.state.termModal}
            toggle={this.togglemodal.bind(this)}
            close={() => this.setState({ termModal: false })}
          ></TermOfUse>
          <Policy
            termModal={this.state.policyModal}
            toggle={this.togglePolicyModal.bind(this)}
            close={() => this.setState({ policyModal: false })}
          ></Policy>
        </div>
      </React.Fragment>
    );
  }
}

Crypto.propTypes = {
  history: PropTypes.object,
};

export default Crypto;

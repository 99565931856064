import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Alert,
  Media,
  Modal,
  NavItem,
  NavLink,
} from "reactstrap";

//Import images
import proImg from "../../assets/images/profile-img.png";
import PropTypes from "prop-types";
import { saveQA } from "../../helpers/kyc_helper";
import MetaTags from "react-meta-tags";
class Questions extends Component {
  constructor(props) {
    super(props);
    const is_q = localStorage.getItem("is_q");

    if (is_q === "1") {
      this.props.history.push("/verification");
    }

    const isOpen = Number(is_q) === -1 || Number(is_q) === 1 ? false : true;
    this.state = {
      isOpen,
      questions: {
        q1: "",
        q2: "",
        q3: "",
      },
      modal: true,
      inputQuestion: "",
      optionInput: "",
      confirmChecked: false,
      needRefuse: true,
      answers: ["", "", ""],
    };
    this.questions = [
      {
        q: "Purpose of buying Crypto?",
        value: "q1",
        refuse: false,
        a: [
          {
            value: "a1",
            text: "Trading Crypto on Binance/Huobi/Okex",
          },
          {
            value: "a2",
            text: "Buying NFT",
          },
          {
            value: "a3",
            text: "Investing forex trading(MT4/MT5)",
            refuse: true,
          },
          {
            value: "a4",
            text: "Withdraw to others’ wallet address",
            refuse: true,
          },
        ],
      },
      {
        q: "Does anyone ask you to buy Crypto from us or say that we are their partner?",
        value: "q2",
        refuse: true,
        a: [
          {
            value: "a1",
            text: "Yes",
            refuse: true,
          },
          {
            value: "a2",
            text: "No",
          },
        ],
      },
      {
        q: "Does anyone teach you to trade Crypto?",
        value: "q3",
        refuse: true,
        a: [
          {
            value: "a1",
            text: "Yes. Someone I met online.",
            refuse: true,
          },
          {
            value: "a2",
            text: "Yes. My family or friends whom I met physically.",
          },
          {
            value: "a3",
            text: "No.",
          },
        ],
      }
    ];
  }
  componentDidMount() {
    const is_q = localStorage.getItem("is_q");
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    if (is_q === "0" && user.qa) {
      const qa = JSON.parse(user.qa);
      this.setState({
        questions: qa,
      });
    }
  }

  submit() {
    let needRefuse = false;
    this.questions.forEach(item => {
      if (item.refuse) {
        needRefuse = true;
      }
    });
    this.setState({
      isOpen: needRefuse,
    });

    saveQA({
      qa: JSON.stringify(this.state.questions),
      is_qa: needRefuse ? 0 : 1,
    }).then(() => {
      localStorage.setItem("is_q", needRefuse ? 0 : 1);
      if (!needRefuse) {
        this.props.history.push("/verification");
      }
    });
  }

  changeQ(question, answer) {
    question.refuse = answer.refuse || false;
    const _questions = this.state.questions;
    _questions[question.value] = answer.value;
    this.setState({
      questions: _questions,
    });
  }
  changeCheck(e) {
    this.setState({
      confirmChecked: e.target.checked,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Questionnaire</title>
          </MetaTags>
          <Container fluid>
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem className="current">
                    <NavLink className="active">
                      <span className="number">1.</span>
                      Questionnaire
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <span className="number">2.</span>
                      ID Verification
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <span className="number">3.</span>
                      Get PayID/Bank Account detail
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </div>
            {/* Render Breadcrumb */}
            <Row className="justify-content-center mt-2 mt-lg-5">
              <Col xl="6" sm="8">
                <Card>
                  <CardBody>
                    <div>
                      <Row className="justify-content-center">
                        <Col lg="10">
                          <Alert isOpen={this.state.isOpen} color="danger">
                            You are at risk of being scammed, please cancel the
                            transaction or contact customer service for further
                            verification.
                          </Alert>
                          {this.questions.map((item, index) => (
                            <div
                              className="text-muted mt-1 mb-5"
                              key={item.value}
                            >
                              <Media className="faq-box mb-4">
                                <div className="faq-icon me-3">
                                  <i className="bx bx-help-circle font-size-20 text-success" />
                                </div>
                                <Media body>
                                  <h5 className="font-size-15 line-height-21 mb-3">
                                    {item.q}
                                  </h5>
                                  {item.a.map(answer => (
                                    <div
                                      className="form-check mb-3"
                                      key={item.value + answer.value}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={item.value}
                                        id={item.value + answer.value}
                                        value={answer.value}
                                        disabled={this.state.isOpen}
                                        checked={
                                          answer.value ==
                                          this.state.questions[item.value]
                                        }
                                        onChange={this.changeQ.bind(
                                          this,
                                          item,
                                          answer
                                        )}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={item.value + answer.value}
                                      >
                                        {answer.text}
                                      </label>
                                    </div>
                                  ))}
                                </Media>
                              </Media>
                            </div>
                          ))}
                          <div>
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="check"
                                onChange={this.changeCheck.bind(this)}
                                id="defaultCheck1"
                              />{" "}
                              I declare that this questionnaire was
                              independently completed by myself, without anyone
                              else's guidance.
                            </label>
                          </div>

                          <div
                            className="mt-4"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {/* button triggers modal */}

                            <Button
                              type="button"
                              color="primary"
                              disabled={
                                this.state.isOpen ||
                                !this.state.confirmChecked ||
                                this.state.questions.q1 == "" || 
                                this.state.questions.q2 == "" || 
                                this.state.questions.q3 == ""
                              }
                              onClick={this.submit.bind(this)}
                            >
                              Next
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-5 mb-2">
                        <Col sm="6" xs="8">
                          <div>
                            <img src={proImg} alt="" className="img-fluid" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal
            isOpen={this.state.modal}
            role="dialog"
            size="lg"
            autoFocus={true}
            centered
            id="verificationModal"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                BEWARE OF SCAMS
              </h5>
              {/* <button
                type="button"
                onClick={() => this.setState({ modal: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className="modal-body scams">
              <div className="bell-alert">
                <i className="mdi mdi-bell-alert"></i>
              </div>
              <div className="pl-2">
                <p>
                  <span className="text-icon">SCAM</span>
                  Never trust anyone online, especially ROMANCE LOVER
                </p>
              </div>
              <div className="pl-2">
                <p>
                <span className="text-icon">SCAM</span>
                  Never trust unrealistic high ROI investments
                </p>
              </div>
              <div className="pl-2">
                <p>
                <span className="text-icon">SCAM</span>
                  Never share your screen to others
                </p>
              </div>
              <div className="pl-2">
                <p>
                <span className="text-icon">SCAM</span>
                  Do not withdraw to unofficial addresses or platforms
                </p>
              </div>
              <div className="pl-2">
                <p>
                <span className="text-icon">SCAM</span>
                  Do not disclose your verification code or account information to anyone
                </p>
              </div>
              <div className="s-mark">
              CANCEL THE ORDER IF SOMEONE TEACHING YOU HOW TO BUY!!!
            </div>
            </div>
       
            <div className="modal-footer scams">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.setState({ modal: false })}
              >
                I UNDERSTAND
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

Questions.propTypes = {
  history: PropTypes.object,
};

export default Questions;

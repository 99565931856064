import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"

//Dropzone
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import images
import verificationImg from "../../assets/images/verification-img.png"
import proImg from "../../assets/images/profile-img.png"
import { options } from "common/data/projects"

import SumsubWebSdk from '@sumsub/websdk-react'
import { getKYCToken, getUserInfo } from  "../../helpers/kyc_helper"
import PropTypes from "prop-types";
class Verification extends Component {
  constructor(props) {
    super(props)
    const is_kyc = localStorage.getItem('is_kyc')
 
    
    if (is_kyc === '1') {
      if (this.props.location.state && this.props.location.state.type == 1) {
        this.props.history.push('/confirm')
      } else {
        this.props.history.push('/get-transfer-detail');
      }
    }
    this.request = null
    this.state = {
      modal: false,
      activeTab: 1,
      selectedFiles: [],
      passedSteps: [1],
      token: ''
    }
    this.togglemodal.bind(this)
    this.toggleTab.bind(this)
    this.handleAcceptedFiles.bind(this)

    getKYCToken({
      "first_name": "BITU",
      "last_name": "TEST",
    }).then((res) => {
      if (res && res.data && res.data.token) {
        this.setState({
          token: res.data.token,
        })
      } else {
        this.props.history.push('/login')
      }
    })
  }

  componentDidMount() {
    this.request = setInterval(() => {
      getUserInfo().then((res) => {
        if (res.data.kyc_status === 'GREEN') {
          clearInterval(this.request)
          localStorage.setItem("is_kyc", 1)
          if (this.props.location.state && this.props.location.state.type == 1) {
            this.props.history.push('/confirm')

          } else {
            this.props.history.push('/get-transfer-detail')

          }
        }
      })
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.request)
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps
        })
      }
    }
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  messageHandler(e) {
    console.log(e)
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Verification</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            {!(this.props.location.state && this.props.location.state.type == 1) ? (
                <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          >
                          <NavLink
                          >
                            <span className="number">1.</span>
                            Questionnaire
                          </NavLink>
                        </NavItem>
                        <NavItem className="current">
                          <NavLink
                            className="active"
                          >
                            <span className="number">2.</span>
                            ID Verification
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                          >
                            <span className="number">3.</span>
                            Get PayID/Bank Account detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </div>
            ) : ''}
      
            <Row className="justify-content-center  mt-2 mt-lg-5">
              <Col xl="6" sm="8">
                {
                  this.state.token !== '' ? (
                    <SumsubWebSdk
                      accessToken={this.state.token}
                      expirationHandler={this.messageHandler}
                      onError={this.messageHandler}
                      onMessage={this.messageHandler}
                    />
                  ) : ''
                }
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Verification.propTypes = {
  history: PropTypes.object,
};

export default Verification;

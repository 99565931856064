import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Col, Container, Row, Alert, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
import { userResetPassword } from "../../store/actions";
class ForgetPwd2 extends Component {
  constructor(props) {
    super(props);
    const searchParams = props.location.search
    const arr = searchParams.split('token=')
    const token = arr[1] || null;

    this.state = {
      token
    };
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>
              Forgot Password
            </title>
          </MetaTags>
          <Container fluid className="p-0 max-none">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="38"
                            className="auth-logo-dark"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Reset Password</h5>
                          <p className="text-muted">  Enter your new password.</p>
                        </div>

                        <div className="mt-4">
                          {this.props.resetError ? (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {this.props.resetError}
                            </Alert>
                          ) : null}
                          {this.props.resetSuccessMsg ? (
                            <Alert color="success" style={{ marginTop: "13px" }}>
                              {this.props.resetSuccessMsg}
                            </Alert>
                          ) : null}
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              password:
                                (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string().required(
                                "Please Enter Valid Password"
                              ),
                            })}
                            onSubmit={values => {
                              this.props.userResetPassword(values, this.state.token, this.props.history);
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="text-end">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {" "}
                                    Submit{" "}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>

                          <div className="mt-5 text-center">
                            <p>
                              Remember It ?{" "}
                              <a
                                href="/login"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Login here{" "}
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} BiTu. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          BiTu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPwd2.propTypes = {
  resetError: PropTypes.func,
  resetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userResetPassword: PropTypes.any,
};

const mapStateToProps = state => {
  const { resetError, resetSuccessMsg } = state.ForgetPassword;
  return { resetError, resetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userResetPassword })(ForgetPwd2)
);

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

//Import images
import proImg from "../../assets/images/coming-soon.svg";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
class Confirm extends Component {
  constructor(props) {
    super(props);
    const is_q = localStorage.getItem("is_q");

    const is_kyc = localStorage.getItem('is_kyc')

    this.state = {
      is_kyc
    };
  }
  componentDidMount() {
    const is_q = localStorage.getItem("is_q");
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
  }

  goPage(type) {
    if (type === 1) {
      this.props.history.push({
        pathname: '/verification', 
        state: { type }
      });
    } else {
      this.props.history.push('/questions');
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Confirm</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Row className="justify-content-center mt-2 mt-lg-5">
              <Col xl="6" sm="8">
                <Card>
                  <CardBody>

                    <Row className="justify-content-center mt-2 mb-2">
                      <Col sm="6" xs="8">
                        <div>
                          <img src={proImg} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{
                      padding: 20
                    }}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ height: 72, fontSize: 18 }}
                        onClick={this.goPage.bind(this, 0)}
                      >
                        <i className="bx bx-cart-alt font-size-24 align-middle me-2"></i>
                        I am buying
                      </button>
                    </Row>
                    {
                      this.state.is_kyc != 1 ? (
                        <Row style={{
                          padding: 20,
                          paddingTop: 0
                        }}>
                          <button
                            type="button"
                            className="btn btn-success"
                            style={{ height: 72, fontSize: 18 }}
                            onClick={this.goPage.bind(this, 1)}
                          >
                            <i className="bx bxl-shopify font-size-24 align-middle me-2"></i>
                            I am selling
                          </button>
                        </Row>
                      ) : ''
                    }
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Confirm.propTypes = {
  history: PropTypes.object,
};
export default Confirm;

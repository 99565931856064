import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"

export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={9}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 mt-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">

                        <div dir="ltr">
                          <Carousel showThumbs={false} className="slider_css">
                            <div>
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                Who is <span className="text-primary">BiTu</span>?
                              </h4>
                              <div className="item">
                                <div className="py-3">
                                  <p className="font-size-16 mb-4">
                                    Ausun Overseas Pty Ltd trading as BiTu, is a certified Merchant in Binance Australia, Huobi Global and OKex. BiTu is registered on AUSTRAC (DCE100662269-001) to provide digital currency exchange services in accordance with the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (AML/CTF Act).                                  </p>
                                  <div>
                                    <h4 className="font-size-16 text-primary">
                                      BiTu
                                    </h4>
                                    <p className="font-size-14 mb-0">
                                      - A Registered DCE Provider
                                    </p>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div>
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                Why <span className="text-primary">BiTu</span>?
                              </h4>
                              <div className="item">
                                <div className="py-3">
                                  <p className="font-size-16 mb-4">
                                    BiTu is Australia's most trusted and secure Crypto merchant, undertaking KYC/AML check for every client to prevent money laundering and terrorism financing. BiTu has built an extremely user-friendly platform that makes it simple for clients to complete their verification and get their unique account number and PayID to transfer AUD.
                                  </p>

                                  <div>
                                    <h4 className="font-size-16 text-primary">
                                      BiTu
                                    </h4>
                                    <p className="font-size-14 mb-0">
                                      - A Certified Merchant
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Carousel>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }
}

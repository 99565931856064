import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && localStorage.getItem("admin") === 'true'&& props.location.pathname !=='/admin') {
        return (
          <Redirect
            to={{ pathname: "/admin", state: { from: props.location } }}
          />
        )
      }
      if (isAuthProtected && localStorage.getItem("admin") === 'true'&& props.location.pathname ==='/admin') {

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
      if (isAuthProtected && localStorage.getItem("admin") !== 'true' && props.location.pathname ==='/admin') {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      if (isAuthProtected && localStorage.getItem("authUser") && (!localStorage.getItem("is_q") || localStorage.getItem("is_q")==0 ) && props.location.pathname !='/questions') {

        return (
          <Redirect
            to={{ pathname: "/questions", state: { from: props.location } }}
          />
        )
      }

      if (isAuthProtected && localStorage.getItem("authUser") && localStorage.getItem("is_q")==1 && (!localStorage.getItem("is_kyc") || localStorage.getItem("is_kyc")== 'RED' ) && props.location.pathname !='/verification') {
        return (
          <Redirect
            to={{ pathname: "/verification", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute

import React, { Component } from "react";
import MetaTags from "react-meta-tags";

import { Container, Row, Col, CardBody, Card, Alert, Tooltip, Modal, Button } from "reactstrap";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
class BankAccount extends Component {
  constructor(props) {
    super(props);
    const payMethod = localStorage.getItem('method');
    const amount = localStorage.getItem('amount');
    const currency = localStorage.getItem('currency');

    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    this.user = user;

    this.state = {
      amount,
      user,
      currency,
      payMethod,
      modal: false,
      ttbottom: false,
    };
  }

  togglemodal() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  back(){
    this.props.history.push('/get-transfer-detail');
  }
  showToast () {
    toastr.success('Copied successfully', '');
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Transfer money to proceed with order</title>
          </MetaTags>

          <div className="account-pages pt-sm-5 ">
            <Container>
              <h2 >Transfer money to proceed with order</h2>
              <Row className="justify-content-center  mt-lg-5">
                <Col md="8" lg="8" xl="8">
                  {
                    this.state.payMethod === 'card' ? (
                      <Alert color="warning">
                        <i className="fas fa-exclamation-circle me-2 font-size-24 float-start"></i>Please use your unique Direct Credit detail below to make the transfer and select select ‘Pay Anyone’ when transferring from online banking or mobile app.
                      </Alert>
                    ) : (
                      <Alert color="warning">
                        <i className="fas fa-exclamation-circle me-2 font-size-24  float-start"></i>Please use your unique PayID detail below to make the transfer and select the email option, and NOT organisation ID when transferring from online banking or mobile app.
                      </Alert>
                    )
                  }
                  <Card className="overflow-hidden">
                    {
                      this.state.payMethod==='card' ? (
                        <CardBody className="pt-4">
                          <Row>
                            <Col sm="12">
                              <div className="title-box clearfix mt-2">
                                <div className="float-start">
                                  <i className="bx bxs-dollar-circle text-primary" /> AUD
                                </div>
                                <div className="float-end">
                                  ${this.state.amount}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="12">
                              <div className="info-title">
                                Direct Credit Information
                              </div>
                              <address className="address-box">
                                <span className="title-left">Account Name:</span>
                                <br />
                                <span>
                                BITU
                              </span>
                                <span className="float-end">

                                <CopyToClipboard  onCopy={this.showToast.bind(this)} text="BITU" >
                                  <i id="TooltipBottom" className="bx bx-copy font-size-20 pointer-event"/>
                                </CopyToClipboard>

                              </span>
                                <br />
                              </address>

                            </Col>
                            <Col sm="12">
                              <address className="address-box">
                                <span className="title-left">BSB Number:</span>
                                <br />
                                <span>
                                 {
                                   this.user?.branch_code
                                 }
                              </span>
                                <span className="float-end">

                                <CopyToClipboard onCopy={this.showToast.bind(this)} text={this.user?.branch_code} >
                                  <i id="TooltipBottom" className="bx bx-copy font-size-20 pointer-event"/>
                                </CopyToClipboard>

                              </span>
                                <br />
                              </address>
                            </Col>
                            <Col sm="12">
                              <address className="address-box">
                                <span className="title-left">Account Number:</span>
                                <br />
                                <span>
                                 {
                                   this.user?.account_number
                                 }
                              </span>
                                <span className="float-end">

                                <CopyToClipboard onCopy={this.showToast.bind(this)} text={this.user?.account_number} >
                                  <i id="TooltipBottom" className="bx bx-copy font-size-20 pointer-event"/>
                                </CopyToClipboard>

                              </span>
                                <br />
                              </address>
                            </Col>
                            <Col sm="12">
                              <address className="address-box">
                                <span className="title-left">Reference (Optional):</span>
                                <br />
                                <span>
                                Purchase
                              </span>
                                <span className="float-end">

                                <CopyToClipboard onCopy={this.showToast.bind(this)} text="Purchase" >
                                  <i id="TooltipBottom" className="bx bx-copy font-size-20 pointer-event"/>
                                </CopyToClipboard>

                              </span>
                                <br />
                              </address>
                            </Col>
                          </Row>
                        </CardBody>
                      ) : (
                        <CardBody className="pt-4">
                          <Row>
                            <Col sm="12">
                              <div className="title-box clearfix mt-2">
                                <div className="float-start">
                                  <i className="bx bxs-dollar-circle text-primary" /> AUD
                                </div>
                                <div className="float-end">
                                  ${this.state.amount}
                                </div>
                              </div>
                            </Col>
                            <Col sm="12">
                              <div className="info-title">
                                PayID Information
                              </div>
                              <address className="address-box">
                                <span className="title-left">PAY ID:</span>
                                <br />
                                <span>
                                 {
                                   this.user?.pay_id
                                 }
                              </span>
                                <span className="float-end">

                                <CopyToClipboard onCopy={this.showToast.bind(this)} text={this.user?.pay_id} >
                                  <i id="TooltipBottom" className="bx bx-copy font-size-20 pointer-event"/>
                                </CopyToClipboard>

                              </span>
                                <br />
                              </address>
                            </Col>

                          </Row>
                        </CardBody>
                      )
                    }

                  </Card>

                  <Button  type="button" className="mb-5" color="warning" onClick={this.back.bind(this)}>
                    Previous
                  </Button>

                </Col>
                <Col md="4">
                  <h4 className="card-title mb-4">How it work</h4>

                  <div>
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">

                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">
                                Transfer Money
                              </h5>
                              <p className="text-muted">
                                Transfer your money to your unique account.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Funds Arrived</h5>
                              <p className="text-muted">
                                Your first transfer may take up to 24 hours to clear subject to your bank’s policy. Subsequent transfers are instant.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="event-list">
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="d-flex">

                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14">Order Completed</h5>
                              <p className="text-muted">
                                Your purchase order on Binance Australia, Huobi Golbal or OKex will be completed.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="important-note mb-5" onClick={() => this.setState({modal: true})}>
                    <i className="mdi mdi-notebook text-warning" style={{marginRight: 10}}/>
                    View Important Notes
                  </div>
                </Col>
              </Row>
              <Tooltip
                placement="bottom"
                isOpen={this.state.ttbottom}
                target="TooltipBottom"
                toggle={() =>
                  this.setState({ ttbottom: !this.state.ttbottom })
                }
              >
                Copy to clipboard
              </Tooltip>
              <Modal
                isOpen={this.state.modal}
                role="dialog"
                size="md"
                autoFocus={true}
                centered
                id="verificationModal"
                toggle={this.togglemodal.bind(this)}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Important Notes
                  </h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ modal: false })
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="pl-2">
                    <i className="mdi mdi-card-account-details text-pri"></i>
                    <p>
                      In line with AML/CTF laws, transfers are only accepted from the bank account that is in the same name as the account you have verified on Binance Australia, Huobi Global and OKex.
                    </p>
                  </div>
                  <div className="pl-2">
                    <i className="bx bx-bitcoin text-pri"></i>
                    <p>
                      To cover processing costs, a AU$3 processing fee will be charged to refund deposits received from third-party bank accounts.
                      Deposits will only be refunded if the deposit is greater than AU$10.
                    </p>
                  </div>
                  <div className="pl-2">
                    <i className="bx bxs-bank text-pri"></i>
                    <p>
                      This service is supported by BiTu, in accordance to BiTu’s Terms of Use and Privacy Policy.
                    </p>
                  </div>
                  <div className="pl-2">
                    <i className="mdi mdi-clock text-pri"></i>
                    <p>
                      Failed deposits will be returned to the source account within 2 business days.
                    </p>
                  </div>
                  <div className="pl-2">
                    <i className="mdi mdi-hours-24 text-pri"></i>
                    <p>
                      Your first transfer may take 24 hours to clear
                      subject to your bank&apos;s policy. Subsequent transfers
                      are instant.
                    </p>
                  </div>
                  <div className="pl-2">
                    <i className="mdi mdi-monitor-eye text-pri"></i>
                    <p>
                      The seller (BiTu) is not responsible for the management of the assets after the purchase order completed.
                    </p>
                  </div>


                </div>

              </Modal>
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
BankAccount.propTypes = {
  history: PropTypes.object,
};

export default BankAccount;

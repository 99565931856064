import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { logOut } from "../../../helpers/kyc_helper"
import {
  postFakeLogin,
  postLogin,
  postRegister,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import Cookies from 'js-cookie'

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(user.isRegister ? postRegister : postLogin, {
      email: user.email.toLowerCase(),
      pass_wd: user.password,
    })

    if (response.code !== 1) {
      yield put(apiError(response.msg))
      return
    }
    const data = response.data
    const _user = {
      ...data,
      uid: data.ID,
      role: "admin",
      email: data.email,
    }
    localStorage.setItem("authUser", JSON.stringify(_user))
    localStorage.setItem("admin", _user.is_admin)

    localStorage.setItem("is_q", data.is_qa === 0 ? 0 : data.is_qa === 1 ? 1 : -1)
    localStorage.setItem("is_kyc", data.kyc_status === 'RED' ? 0 : data.kyc_status === 'GREEN' ? 1 : -1)
    yield put(loginSuccess(_user))
    if (data.is_qa === 1) {
      history.push("/verification")
    } else {
      history.push("/confirm")
    }

  } catch (error) {
    console.log(error)
    if (typeof error == 'string') {
      yield put(apiError(error))
    } else {
      yield put(apiError('Network exception.'));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    logOut().then(() => {

    })
    localStorage.removeItem("authUser")
    localStorage.removeItem("is_q")
    localStorage.removeItem("method")
    localStorage.removeItem("admin")
    Cookies.remove('auth')
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

//Import images
// import proImg from "../../assets/images/";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import qs from "qs";
import { getPoli } from "../../helpers/kyc_helper";
import { rsort } from "semver";
class Payinfo extends Component {
  constructor(props) {
    super(props);

    const paramStr = this.props.location.search.slice(1);
    const paramObj = qs.parse(paramStr);

    const is_q = localStorage.getItem("is_q");

    const is_kyc = localStorage.getItem("is_kyc");

    this.state = {
      is_kyc,
      ref: paramObj.ref,
      res: false,
      code: "",
    };
    this.getPoliInfo();
  }

  getPoliInfo() {
    getPoli({
      ref: this.state.ref,
    }).then(res => {
      console.log(res);
      this.setState({
        res: true,
        code: res.data.TransactionStatusCode,
      });
    });
  }
  componentDidMount() {
    const is_q = localStorage.getItem("is_q");
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
  }

  goPage(type) {
    this.props.history.push('/get-transfer-detail');
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Confirm</title>
          </MetaTags>
          <Container fluid>
            <Row className="justify-content-center mt-2 mt-lg-5">
              <Col xl="4" sm="6">
                <Card>
                  <CardBody>
                    {this.state.res ? (
                      <div>
                        {(() => {
                          switch (this.state.code) {
                            case "Completed":
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                    fontSize: 50,
                                  }}
                                >
                                  <i
                                    className="mdi mdi-cart-check align-middle me-2"
                                    style={{
                                      color: "green",
                                      fontSize: 50,
                                    }}
                                  ></i>{" "}
                                  Success
                                </div>
                              );
                            case "ReceiptUnverified":
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontSize: "2em",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-cart-off align-middle me-2"
                                    style={{
                                      color: "red",
                                    }}
                                  ></i>{" "}
                                  Receipt Unverified
                                  <div
                                    style={{
                                      marginTop: 20,
                                      color: "black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 16,

                                        fontWeight: "bold",
                                      }}
                                    >
                                      What should I do now?
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 14,
                                      }}
                                    >
                                      Firstly, check your bank account to see
                                      if money has left your account. If it has,
                                      you should email support team with a
                                      screenshot of their bank statement to have
                                      assistance getting credited for the
                                      purchase made. If money has not left your
                                      account, you may like to retry the payment
                                      again. If the error persists, try again
                                      later.
                                    </div>
                                  </div>
                                </div>
                              );
                            case "Failed":
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontSize: "2em",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-cart-off align-middle me-2"
                                    style={{
                                      color: "red",
                                    }}
                                  ></i>{" "}
                                  Transaction failed
                                  <div
                                    style={{
                                      marginTop: 20,
                                      color: "black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 16,

                                        fontWeight: "bold",
                                      }}
                                    >
                                      What should I do now?
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 14,
                                      }}
                                    >
                                      Please click back button, try again. If
                                      the error persists, please re-attempt your
                                      payment at another time.
                                    </div>
                                  </div>
                                </div>
                              );
                            case "CancelledPayment":
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontSize: "2em",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-cart-off align-middle me-2"
                                    style={{
                                      color: "red",
                                    }}
                                  ></i>{" "}
                                  Cancelled Payment
                                  <div
                                    style={{
                                      marginTop: 20,
                                      color: "black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 16,

                                        fontWeight: "bold",
                                      }}
                                    >
                                      What just happend?
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 14,
                                      }}
                                    >
                                      You have chosen to cancel your payment.
                                    </div>
                                  </div>
                                </div>
                              );
                            default:
                              return <div>{this.state.code}</div>;
                          }
                        })()}

                        <Row
                          style={{
                            padding: 20,
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.goPage.bind(this, 0)}
                          >
                            Back
                          </button>
                        </Row>
                      </div>
                    ) : (
                      "Loading..."
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Payinfo.propTypes = {
  history: PropTypes.object,
};
export default Payinfo;

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap"
import TermOfUse from "../../pages/Authentication/TermOfUse";
import Policy from "../../pages/Authentication/Policy";
import { Link } from "react-router-dom";

class Footer extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      termModal: false,
      checkedPolicy: false,
      policyModal: false,
    };
  }
  togglemodal() {
    this.setState(prevState => ({
      termModal: !prevState.termModal,
    }))
  }

  togglePolicyModal() {
    this.setState(prevState => ({
      policyModal: !prevState.policyModal,
    }))
  }
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container fluid={true}>
            <Row className="d-lg-none">
              <Col md={6}>
                <a target="_blank" href="mailto:support@bitu.co" className="text-primary btn btn-secondary btn-sm btn btn-secondary m-link">
                  Support
                </a>
                <Link to="#" onClick={()=>this.setState({policyModal: true})} className="text-primary btn btn-secondary btn-sm btn btn-secondary m-link">
                  Privacy Policy
                </Link>
                <Link to="#" onClick={()=>this.setState({termModal: true})} className="text-primary btn btn-secondary btn-sm btn btn-secondary m-link">
                  Terms and Conditions
                </Link>
              </Col>

              <Col md={6}>© 2022 BiTu. All rights reserved.</Col>
            </Row>
            <Row className="d-none d-sm-flex">
              <Col md={6}>© 2022 BiTu. All rights reserved.</Col>

              <Col md={6}>
                <div className="text-sm-end d-none d-sm-block">
                  <div className="footer-item">
                    <a target="_blank" href="mailto:support@bitu.co" className="text-info">
                      Support
                    </a>
                  </div>
                </div>
                <div className="text-sm-end d-none d-sm-block">
                  <div className="footer-item">
                    <Link to="#" onClick={()=>this.setState({policyModal: true})} className="text-info">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
                <div className="text-sm-end d-none d-sm-block">
                  <div className="footer-item">
                    <Link to="#" onClick={()=>this.setState({termModal: true})} className="text-info">
                      Terms and Conditions
                    </Link>
                  </div>
                </div>
              </Col>

            </Row>
            <TermOfUse termModal={this.state.termModal} toggle={this.togglemodal.bind(this)} close={() => this.setState({termModal: false})}></TermOfUse>
            <Policy termModal={this.state.policyModal} toggle={this.togglePolicyModal.bind(this)} close={() => this.setState({policyModal: false})}></Policy>
          </Container>
        </footer>
      </React.Fragment>
    )
  }

}

export default Footer

import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login2"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register2"
import ForgetPwd from "../pages/Authentication/ForgetPwd2"
import ResetPwd from "../pages/Authentication/ResetPwd"

// Dashboard
import Questions from "../pages/Questions/index"
import Confirm from "../pages/Confirm/index"
import Verification from "../pages/Verification/index"
import Crypto from "../pages/Crypto/index"
import BankAccount from "../pages/BankAccount/index"
import Users from "../pages/Admin/index"
import Payinfo from "../pages/Crypto/payinfo"

const authProtectedRoutes = [
  { path: "/questions", component: Questions },
  { path: "/confirm", component: Confirm },
  { path: "/checkout", component: Payinfo },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/verification", component: Verification },
  { path: "/account", component: BankAccount },
  { path: "/get-transfer-detail", component: Crypto },
  { path: "/admin", component: Users },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/questions" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPwd },
  { path: "/register", component: Register }
]

export { authProtectedRoutes, publicRoutes }

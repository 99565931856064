import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import {
  postFakeRegister,
} from "../../../helpers/fakebackend_helper"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postFakeRegister, {
      email: user.email.toLowerCase(),
      first_name: user.username,
      last_name: user.username,
      pass_wd: user.password
    })
    if (response.code !== 1) {
      yield put(apiError(response.msg))
      return
    }
    const data = response.data
    const _user = {
      ...data,
      uid: data.ID,
      role: "admin",
      email: data.email.toLowerCase(),
    }
    localStorage.setItem("authUser", JSON.stringify(_user))

    localStorage.setItem("is_q", data.is_qa === 0 ? 0 : data.is_qa === 1 ? 1 : -1)
    localStorage.setItem("is_kyc", data.kyc_status === 'RED' ? 0 : data.kyc_status === 'GREEN' ? 1 : -1)
    yield put(registerUserSuccessful(_user))
    history.push("/questions")
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser)
}

export default accountSaga

import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd, postResetPwd,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postFakeForgetPwd, { email: user.email })
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Please check your email to reset your password."
        )
      )
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* resetPassword({ payload: { user, token, history } }) {
  console.log(token)
  try {
    const response = yield call(postResetPwd, { new_pass: user.password, token })

    if (response) {
      yield put(userResetPasswordSuccess(
        'Your password has been reset successfully. Please login again.'
      ))
    }
  } catch (error) {
    yield put(userResetPasswordError(
      'The password reset page has expired. Please resend the email.'
    ))
  }

}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default forgetPasswordSaga

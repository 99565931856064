import axios from "axios";
// import accessToken from "./jwt-token-access/accessToken";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//pass new generated access token here
import Cookies from 'js-cookie'

//apply base url for axios
const API_URL = "/api";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

// axiosApi.interceptors.response.use(
//   response => response,
//   error => {
//     return Promise.reject(error);
//   }
// );
// 响应拦截器
const errorCode = {
  '401': 'Login expired, please login again',
  '403': 'Login expired, please login again',
  '404': '404 not found',
  'default': 'Network exceptions, please refresh the page'
}
axiosApi.interceptors.response.use(
  res => {
    console.log(res)
    // 未设置状态码则默认成功状态
    const code = res.status || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      toastr.error(
        "Login expired, please login again"
      );
    } else if (code === 403) {
      toastr.error(
        "Login expired, please login again"
      );
      window.location = '/';
    } else if (code === 500) {
      toastr.error(msg);
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      toastr.error(msg);
      return Promise.reject("error");
    } else {
      return res;
    }
  },
  error => {
    console.log("err:" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "Network Error";
    } else if (message.includes("timeout")) {
      message = "Request time out";
    } else if (message.includes("Request failed with status code")) {
      const code = Number(message.substr(message.length - 3));
      console.log(code)
      if (code === 401) {
        toastr.error(
          "Login expired, please login again"
        );
        localStorage.removeItem("authUser")
        localStorage.removeItem("is_q")
        localStorage.removeItem("method")
        localStorage.removeItem("admin")
        Cookies.remove('auth')
        setTimeout(() => {
          window.location = '/';
        }, 2000)
      } else if (code === 403) {
        toastr.error(
          "Login expired, please login again"
        );
        localStorage.removeItem("authUser")
        localStorage.removeItem("is_q")
        localStorage.removeItem("method")
        localStorage.removeItem("admin")
        Cookies.remove('auth')
        setTimeout(() => {
          window.location = '/';
        }, 2000)
      } else if (code === 500) {
        toastr.error(msg);
      
      } else if (code !== 200) {
        toastr.error(msg);
      }
    }
    
    toastr.error(message);
    return Promise.reject(error);
  }
);
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
